import {Excel, Sheet} from "@/domain/model/excel/excel.model";
import {CreateMaterial} from "@/domain/command/material/material.command";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {useMaterialApi} from "@/domain/service/api/material.api";
import _ from "lodash";
import {Component, Material} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
import {loadingController} from "@ionic/vue";
import {find} from "@vue/test-utils/dist/utils/find";

const findFirstCoordinatesIndexMaterialReferenceWithKit = (sheet: Sheet): { x: number; y: number } => {

    for (let colIndex = 0; colIndex < sheet.cellMatrix.length; colIndex++) {
        for (let rowIndex = 0; rowIndex < sheet.cellMatrix[colIndex].length; rowIndex++) {
            const cell = sheet.cellMatrix[colIndex][rowIndex];

            if (!cell)
                continue;

            if (!_.isString(cell.value) || cell.value.toLowerCase() !== 'referencia')
                continue;

            return {x: colIndex, y: rowIndex + 1};
        }
    }

    return {x: -1, y: -1};
}

export function useKitImporter() {
    const store = useStore();
    const importFromExcel = async (excel: Excel) => {
        const loading = await loadingController
            .create({
                message: 'Cargando excel, esta acción puede tardar algunos minutos...',
                duration: 100000,
            });

        await loading.present();
        const sheet = excel.sheets[0];
        const materialCommands: CreateMaterial[] = [];
        const kitCommands: CreateMaterial[] = [];
        const components: Component[] = [];
        // const firstRowWithMaterialRef = findFirstCoordinatesIndexMaterialReferenceWithKit(sheet);
        // const firstRowWithKit = {x: firstRowWithMaterialRef.x + 3, y: firstRowWithMaterialRef.y};
        // const firstRowWithQuantity = {x: firstRowWithMaterialRef.x + 2, y: firstRowWithMaterialRef.y};
        const firstRowWithMaterialRef = {x: 0, y: 1};
        const firstRowWithKit = {x: 3, y: 1};
        const firstRowWithQuantity = {x: 2, y: 1};


        for (let index = 0; index < sheet.cellRows.length; index++) {
            const row = sheet.cellRows[index];

            if (index < firstRowWithKit.y)
                continue;

            if (!row[firstRowWithKit.x])
                continue;


            const materialRef = row[firstRowWithMaterialRef.x].value.toString();
            const descriptionMaterial = row[firstRowWithMaterialRef.x + 1].value.toString();
            if (!store.getters.materialByRef(materialRef)) {
                const command: CreateMaterial = {
                    id: uuid4(),
                    name: descriptionMaterial,
                    ref: materialRef,
                    type: 'part',
                    components: [],
                    scrapRatio: 0,
                    group: '',
                };

                materialCommands.push(command);
            } else
                continue;
            const kitRef = row[firstRowWithKit.x].value.toString();
            const component: any = {
                ref: row[firstRowWithMaterialRef.x].value.toString(),
                amount: parseInt(row[firstRowWithQuantity.x].value.toString()),
                kitRef: kitRef,
            };
            components.push(component);

            if (!store.getters.materialByRef(kitRef)) {
                const command: CreateMaterial = {
                    id: uuid4(),
                    name: '',
                    ref: kitRef,
                    type: 'production',
                    components: [component],
                    scrapRatio: 0.01,
                    group: '',
                };
                kitCommands.push(command);
            } else {
                continue;
            }
        }
        kitCommands.forEach((kit: Material) => {
            kit.components = (_.filter(components, (component: any) => component.kitRef === kit.ref)).map((item) => {
                return {
                    id: uuid4(),
                    ref: item.ref,
                    amount: item.amount,
                }
            });

        });
        const notRepeatedKits = _.unionBy(kitCommands, 'ref');
        const materials = notRepeatedKits.concat(materialCommands);
        const notRepeatedMaterials = _.unionBy(materials, 'ref');
        await useMaterialApi().createMaterials(notRepeatedMaterials);
        await loading.dismiss();
    }

    return {importFromExcel};
}