
import {IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow, isPlatform} from "@ionic/vue";
import {computed, defineComponent, ref} from "vue";
import router from "@/app/router";
import {actualOrdersAmount} from "@/domain/service/model/material.service";
import {Material} from "@/domain/model/material/material.model";

export default defineComponent({
  name: "ProductionListItem",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonItem,
    IonLabel,
    // IonBadge,
  },

  props: {
    product: null
  },
  setup(prop: any) {
    const productRef = ref<Material>(prop.product);
    const actualOrders = computed(() => actualOrdersAmount(productRef.value));
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));


    const onProductDetails = () => {
      router.push({
        name: 'detailsProduct',
        params: {id: productRef.value.id}
      });
    };

    return {
      isTablet,
      productRef,
      onProductDetails,
      actualOrders
    };
  }
})
