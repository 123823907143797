
import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonCard,
  IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
  IonCol,
  IonContent, IonGrid, IonItemDivider, IonList,
  IonPage, IonRow, IonSearchbar, IonText, isPlatform, onIonViewWillEnter,
} from "@ionic/vue";
import ProductionItemListComponent from "@/app/view/production/list/ProductionItemListComponent.vue";
import {useStore} from "vuex";
import {Material} from "@/domain/model/material/material.model";
import _, {map} from "lodash";
import ProductionListXlsImporter from "@/app/view/production/list/ProductListXlsImporterComponent.vue";
import {useMaterialApi} from "@/domain/service/api/material.api";
import router from "@/app/router";
import {NButton, NDataTable, NSpace} from "naive-ui";
import {actualOrdersAmount} from "@/domain/service/model/material.service";

export default defineComponent({
  name: "ProductionRecord",
  components: {
    ProductionListXlsImporter,
    ProductionItemListComponent,
    IonPage,
    IonContent,
    IonItemDivider,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonText,
    IonList,
    IonSearchbar,
    NSpace,
    NDataTable,
  },
  setup() {
    const store = useStore();
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const searchWord = ref('');
    const data = ref<any>([]);

    const searchMaterial = (material: Material, word: string): boolean => {
      return material.ref.toLowerCase().includes(word.toLowerCase()) ||
      material.name.toLowerCase().includes(word.toLowerCase())
      || material.group.toLowerCase().includes(word.toLowerCase())
    };
    const filterMaterial = (materials: Material[], word: string): Material[] => _.filter(materials,
        (material: Material) => searchMaterial(material, word));
    const sortMaterial = (materials: Material[]): Material[] => _.sortBy(materials, (material: Material) => material.ref.toLowerCase());
    const actualOrders = (material: Material) => actualOrdersAmount(material);
    const justTypeMaterial = (materials: Material[]): Material[] => _.filter(materials, (material: Material) => material.type === 'production');

    const products = computed(() => sortMaterial(filterMaterial(justTypeMaterial(store.getters.allMaterial), searchWord.value)));
    const isEmpty = computed(() => store.getters.allMaterial.length === 0);

    const onProductDetails = (id: string) => {
      router.push({
        name: 'detailsProduct',
        params: {id: id}
      });
    };
    const replaceData = () => {
      data.value =
          map(products.value, (material: Material) => {
            return {
              id: material.id,
              ref: material.ref,
              actualOrders: actualOrders(material),
            };
          });
    };

    watch(products, () => replaceData(), {immediate: true});

    onIonViewWillEnter(() => {
      useMaterialApi().fetchAll();
    })

    return {
      data,
      columns: [
        {
          title: "Referencia producto acabado",
          key: "ref",
          sorter: 'default'
        },
        {
          title: "Pedidos actuales",
          key: "actualOrders",
          sorter: 'default'
        },
        {
          title: 'Acciones',
          key: 'actions',
          render(row: any): any {
            return h(
                NButton,
                {
                  size: 'small',
                  onClick: () => onProductDetails(row.id),
                },
                {default: () => 'Detalles'},
            );
          },

        },
      ],
      products,
      isEmpty,
      searchWord,
      isTablet,
    };
  }
})
